import "core-js/modules/es.function.name.js";
import "core-js/modules/es.string.trim.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "release"
  }, [_c("a-form", {
    attrs: {
      form: _vm.createForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "任务名称(to User)"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["title", {
        rules: [{
          required: true,
          message: "请输入任务名称"
        }]
      }],
      expression: "[\n                    'title',\n                    {\n                        rules: [\n                            { required: true, message: '请输入任务名称' },\n                        ],\n                    },\n                ]"
    }]
  })], 1), _c("a-form-item", {
    attrs: {
      label: " 任务描述(to User)"
    }
  }, [_c("a-textarea", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["memo", {
        rules: [{
          required: true,
          message: "请输入任务描述"
        }]
      }],
      expression: "[\n                    'memo',\n                    {\n                        rules: [\n                            { required: true, message: '请输入任务描述' },\n                        ],\n                    },\n                ]"
    }],
    attrs: {
      autoSize: true
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "任务类型"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["type", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                    'type',\n                    {\n                        rules: [{ required: true, message: '请选择' }],\n                    },\n                ]"
    }],
    attrs: {
      allowClear: "",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择任务类型",
      "option-label-prop": "label"
    },
    on: {
      change: _vm.handleChange
    }
  }, _vm._l(_vm.typeList, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "对象链接"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["config", {
        rules: [{
          required: _vm.checkNick,
          message: "请输入对象链接"
        }]
      }],
      expression: "[\n                    'config',\n                    {\n                        rules: [\n                            {\n                                required: checkNick,\n                                message: '请输入对象链接',\n                            },\n                        ],\n                    },\n                ]"
    }],
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "奖励数量"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["amount", {
        rules: [{
          required: true,
          message: "请输入奖励数量"
        }]
      }],
      expression: "[\n                    'amount',\n                    {\n                        rules: [\n                            { required: true, message: '请输入奖励数量' },\n                        ],\n                    },\n                ]"
    }],
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "奖励日上限"
    }
  }, [_c("a-input", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["maxamount", {
        rules: [{
          required: true,
          message: "请输入奖励日数量"
        }]
      }],
      expression: "[\n                    'maxamount',\n                    {\n                        rules: [\n                            { required: true, message: '请输入奖励日数量' },\n                        ],\n                    },\n                ]"
    }],
    on: {
      change: function change(e) {
        return e.target.value = e.target.value.trim();
      }
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "奖励单位"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["brickstype", {
        rules: [{
          required: true,
          message: "请选择"
        }]
      }],
      expression: "[\n                    'brickstype',\n                    {\n                        rules: [{ required: true, message: '请选择' }],\n                    },\n                ]"
    }],
    attrs: {
      allowClear: "",
      "show-search": "",
      "filter-option": _vm.filterOption,
      placeholder: "请选择奖励",
      "option-label-prop": "label"
    }
  }, _vm._l(_vm.bricksType, function (item) {
    return _c("a-select-option", {
      key: item.id,
      attrs: {
        value: item.id,
        label: item.name
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 1)], 1), _c("a-form-item", {
    attrs: {
      label: "展示时间"
    }
  }, [_c("a-range-picker", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["createtime", {
        rules: [{
          required: true,
          message: "请选择展示时间"
        }]
      }],
      expression: "[\n                    'createtime',\n                    {\n                        rules: [\n                            { required: true, message: '请选择展示时间' },\n                        ],\n                    },\n                ]"
    }],
    attrs: {
      "show-time": {
        defaultValue: [_vm.moment("00:00:00", "HH:mm:ss"), _vm.moment("23:59:59", "HH:mm:ss")]
      },
      format: "YYYY-MM-DD HH:mm:ss"
    },
    on: {
      change: _vm.onDateChange
    }
  }, [_c("a-icon", {
    attrs: {
      slot: "suffixIcon",
      type: "schedule"
    },
    slot: "suffixIcon"
  })], 1)], 1)], 1), _c("div", {
    staticClass: "footer"
  }, [_c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["infoManagement:btn:issue"],
      expression: "['infoManagement:btn:issue']"
    }],
    staticClass: "btn_l",
    attrs: {
      size: "large",
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.handleAdd();
      }
    }
  }, [_vm._v("提交 ")])], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };